/* App.css */

@font-face {
  font-family: 'BillCorporateNarrow';
  src: url('../../public/fonts/billcorpnar-semibold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  width: 100%;
  font-family: 'BillCorporateNarrow';
}

#root {
  width: 100%;
  height: 100%;
}

.App {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
}

.mobile-container {
  display: none;
}

header, footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 13% 0 13.5%;
  background: #F4F3F8;
}

header {
  flex: 0 0 10%;
  padding-top: 11px;
}

footer {
  flex: 0 0 15%;
}

.logo {
  width: 14%;
  height: auto;
}

main {
  display: flex;
  flex: 1 0 auto;
  padding: 0 5% 0 13.8%;
  background: #F4F3F8;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
}

.desktop-title {
  width: 93%;
  padding-top: 6%;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: 68px;
  line-height: 101px;
  letter-spacing: -1.6px;
}

.desktop-title span {
  color: #2B93AC;
}

h2 {
  padding-top: 1%;
  font-size: 1.5rem;
  margin-bottom: 20px;
  letter-spacing: 1.9px;
}

.qr-codes {
  display: flex;
  padding-top: 5%;
  gap: 14%;
}

.qr-code {
  width: 43%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1rem;
}

.qr-code a {
  width: 100%;
  text-decoration: none;
}

.qr-code button {
  width: 100%;
  border: none;
  border-radius: 22px;
  opacity: 0px;
  background: #057D8B;

  font-size: 18px;
  font-weight: 500;
  line-height: 23.25px;
  text-align: center;
  color: #F4F3F8;

  cursor: pointer;
  z-index: 2;
}

.qr-code-image {
  width: 100%;
  height: auto;
}

.qr-button {
  display: flex;
  width: 100%;
  margin-top: 8%;
  align-items: center;
  cursor: pointer;
  background: black;
  border: 2px solid lightgray;
  border-radius: 15px;
}

.qr-code-button-image {
  width: 30%;
  height: auto;
  padding: 10px 5px;
}

.qr-button-text-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
}

.qr-button-app-name {
  font-size: 23px;
}

.image-container {
  flex: 1;
  position: relative;
  top: -72px;
  left: -123px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.image-container-wrapper {
  position: relative;
}

.general-image {
  max-width: 95%;
  max-height: 95%;
}

.image-button {
  position: absolute;
  padding: 10px 20px;
  font-size: 1rem;
}

.small-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 13%;
  position: absolute;
  background-color: white;
  border: none;
  border-radius: 20px;
  color: #057D8B;
  font-size: 1.3vw;
  font-weight: 550;
  line-height: 31px;
  box-shadow: 0px 24px 50px 0px #00000026;
}

.small-button img {
  width: 100%;
  height: auto;
}

.button1 { top: 29%; left: 15%; padding: 2.5%; width: auto; }
.button2 { top: 22%; right: 4%; padding: 2.5%; width: auto;  }
.button3 { top: 73.5%; left: 27%; padding: 1.2% 1.15%;}
.button4 { top: 88%; left: 40%; padding: 1% 1.15%;}
.button5 { top: 90%; left: 59.5%; padding: 1.2 0.9;}
.button6 { top: 81.5%; left: 77%; padding: 0.05% 1.2%;}
.button7 { top: 58%; left: 85%; padding: 0.4% 1.2%;}

.icons {
  position: absolute;
  margin-top: 12vh;
  margin-left: 12vh;
}

footer button {
  position: relative;
  top: -10px;
  width: 240px;
  height: 60px;
  padding: 8px;
  gap: 8px;
  border: none;
  border-radius: 22px;
  opacity: 0px;
  background: #057D8B;

  font-size: 18px;
  font-weight: 500;
  line-height: 23.25px;
  text-align: center;
  color: #F4F3F8;

  cursor: pointer;
}

.footer {
  position: relative;
  width: 100%;
  height: 100px;
  text-align: center;
  overflow: visible;
}

.footer-image {
  position: absolute;
  bottom: 0;
  left: 53.5%;
  transform: translateX(-50%);
  width: 25%;
  height: auto;
}

.mobile-container {
}

.main-content-container {
  padding-top: 32px;
  padding-bottom: 103px;
}

.gradient-container {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 50%),
    linear-gradient(180deg, rgba(27, 135, 161, 0) 0%, rgba(27, 135, 161, 0.623493) 62.35%, rgba(27, 135, 161, 0.583493) 99.99%, #1B87A1 100%);
  padding: 0 16px;
}

.squares-container {
  position: relative;
  padding-bottom: 25px;
}

.empty-square {
  position: absolute;
  width: 95%;
  height: 100%;
  margin-left: 20px;
  border: 10px solid #2B93AC
}

.content-square {
  padding-top: 19px;
}

.content-square-container {
  padding: 29px 27px 25px 18px;
  position: relative;
  width: 95%;
  background: #FFFFFF;
  text-align: left;
}

.mobile-title {
  font-size: 25px;
  font-weight: 500;
  line-height: 37.5px;
  text-align: left;
  color: #272628;

  text-transform: uppercase;
}

.mobile-title span {
  font-size: 25px;
  font-weight: 500;
  line-height: 37.5px;
  text-align: left;

  color: #F2A217;

  text-transform: uppercase;
}

.mobile-subtitle {
  padding-top: 17px;

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;

  color: #6C6C72;
}

.mobile-application-text {
  padding-top: 41px;

  font-size: 20px;
  font-weight: 450;
  line-height: 30px;
  text-align: left;

  color: #333333;

  text-transform: uppercase;
}

.mobile-application-buttons-container {
  display: flex;
  gap: 15px
}

.mobile-application-buttons-container {
  text-decoration: none;
}

.mobile-qr-button {
  display: flex;
  width: 120px;
  height: 40px;
  align-items: center;
  cursor: pointer;
  background: black;
  border: 1px solid lightgray;
  border-radius: 7px;
}

.mobile-qr-code-button-image {
  width: 30%;
  height: auto;
  padding: 5px 2px;
}

.mobile-qr-button-text-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
}

.mobile-qr-button-text {
  font-size: 9px;
}

.mobile-qr-button-app-name {
  font-size: 12px;
}

.mobile-footer-container {
  padding-top: 58px;
  display: flex;
  justify-content: center;
  position: relative;
}

.mobile-phone-icons {
  position: absolute;
  top: 14px;
}

@media (max-width: 1900px) {
  .desktop-title {
    font-size: 58px;
  }

  .qr-code {
    width: 40%;
    margin-bottom: 10px;
  }
}

@media (max-width: 1650px) {
  .desktop-title {
    font-size: 46px;
    line-height: 90px;
  }

  .image-container {
    top: -30px;
    left: -123px;
  }

  .qr-code {
    width: 35%;
  }

  .qr-button-app-name {
    font-size: 19px;
  }
}

@media (max-width: 1366px) {
  .desktop-title {
    font-size: 40px;
    line-height: 70px;
  }

  .qr-codes {
    padding-top: 3%;
  }

  .qr-code {
    width: 30%;
    margin-bottom: 10px;
  }

  .image-container {
    top: -30px;
    left: -123px;
  }

  .qr-button {
    border-radius: 10px;
  }

  .qr-button-text {
    font-size: 12px;
  }

  .qr-button-app-name {
    font-size: 16px;
  }
}

@media (max-width: 1200px) {
  .desktop-title {
    font-size: 34px;
    line-height: 62px;
  }

  h2 {
    font-size: 1rem;
    line-height: 20px;
  }

  .qr-codes {
    padding-top: 3%;
  }

  .qr-code {
    width: 25%;
    margin-bottom: 10px;
  }

  .image-container {
    top: -20px;
    left: -123px;
  }

  .qr-button-text {
    font-size: 11px;
  }

  .qr-button-app-name {
    font-size: 13px;
  }
}

@media (max-width: 1024px) {
  .desktop-title {
    font-size: 20px;
    line-height: 42px;
  }

  h2 {
    font-size: 0.6rem;
    line-height: 16px;
  }

  .qr-button {
    border-radius: 10px;
  }

  .qr-button-text {
    font-size: 7px;
  }

  .qr-button-app-name {
    font-size: 9px;
  }
}

@media (max-width: 768px) {
  .mobile-container {
    display: block;
  }

  .desktop-header, .desktop-main, footer {
    display: none;
  }

  header, footer {
    padding: 0 16px;
    background: none;
  }

  .logo {
    width: 150px;
  }

  .language-icon{
    width: 35px;
    height: 35px;
  }
}