.language-selector {
  padding-bottom: 5px;
  position: relative;
  display: flex;
  align-items: center;
  z-index: 1000;
  gap: 18px;
}

.menu-icon {
  padding: 14px 0;
  cursor: pointer;
}

.language-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s;
}

.language-icon img {
  width: 100%;
  height: auto;
}

.language-dropdown {
  padding: 5px;
  position: absolute;
  top: -32%;
  left: -310px;
  transform: translateY(-50%) translateX(-100%);
  background-color: #FFFFFF;
  border: 1px solid #ccc;
  box-shadow: 0 8px 16px rgba(0,0,0,0.2);
  border-radius: 5px;
  z-index: 1000;
  animation: fadeInLeft 0.3s forwards, fadeOutLeft 0.3s reverse forwards;
}

.language-dropdown ul {
  list-style: none;
  margin: 0;
  display: flex;
}

.language-dropdown li {
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.language-dropdown li:hover {
  background-color: #FFFFFF;
}

.language-dropdown li img {
  width: 40px;
  height: auto;
}

.language-dropdown li img:focus,
.language-dropdown li img:active {
  outline: none;
}

.language-dropdown li:hover img {
  transform: scale(1.2);
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(10px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@media (max-width: 768px) {
  .language-selector {
    gap: 10px;
  }

  .language-dropdown {
    top: -19%;
    left: -245px;
    padding: 5px;
  }

  .language-dropdown li {
    padding: 5px;
  }

  .language-dropdown li img{
    width: 35px;
  }
}